import { useShepherd } from "vue-shepherd/dist/vue-shepherd.ssr.js";

export function useOnboardingTour() {
  const { t } = useI18n();
  const tour = useShepherd({
    useModalOverlay: true,
    defaultStepOptions: {
      exitOnEsc: true,
      scrollTo: { behavior: "smooth", block: "center" },
      classes: "text-justify px-3 rounded-2xl",
      cancelIcon: {
        enabled: true,
      },
    },
  });

  const addSteps = () => {
    tour.addStep({
      title: t("onboardingTour.step_0.title"),
      text: t("onboardingTour.step_0.text", {
        emphasized: `<strong>${t("onboardingTour.step_0.emphasized")}</strong>`,
      }),
      attachTo: {
        element: "#tour-btn",
        on: "top",
      },
      classes: "!top-[calc(100vh-225px)]",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_0.buttonLeft"),
          action: tour.cancel,
        },
        {
          text: t("onboardingTour.step_0.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_1.title"),
      text: t("onboardingTour.step_1.text", {
        emphasized: `<strong>${t("onboardingTour.step_1.emphasized")}</strong>`,
      }),
      attachTo: {
        element: "#step-1",
        on: "bottom",
      },
      classes: "mt-6",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_1.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_1.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_2.title"),
      text: t("onboardingTour.step_2.text", {
        emphasized: `<strong>${t("onboardingTour.step_2.emphasized")}</strong>`,
        emphasized2: `<strong>${t("onboardingTour.step_2.emphasized2")}</strong>`,
      }),
      attachTo: {
        element: "#step-2",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_2.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_2.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_3.title"),
      text: t("onboardingTour.step_3.text", {
        emphasized: `<strong>${t("onboardingTour.step_3.emphasized")}</strong>`,
      }),
      attachTo: {
        element: "#step-3",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_3.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_3.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_4.title"),
      text: t("onboardingTour.step_4.text", {
        emphasized: `<strong>${t("onboardingTour.step_4.emphasized")}</strong>`,
      }),
      attachTo: {
        element: "#step-4",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_4.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_4.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_5.title"),
      text: t("onboardingTour.step_5.text", {
        emphasized: `<strong>${t("onboardingTour.step_5.emphasized")}</strong>`,
      }),
      attachTo: {
        element: "#step-5",
        on: "right",
      },
      classes: "ml-5",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_5.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_5.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_6.title"),
      text: t("onboardingTour.step_6.text", {
        emphasized: `<strong>${t("onboardingTour.step_6.emphasized")}</strong>`,
        emphasized2: `<strong>${t("onboardingTour.step_6.emphasized2")}</strong>`,
      }),
      attachTo: {
        element: "#step-6",
        on: "left",
      },
      classes: "ml-[-25px]",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_6.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_6.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_7.title"),
      text: t("onboardingTour.step_7.text", {
        emphasized: `<strong>${t("onboardingTour.step_7.emphasized")}</strong>`,
        emphasized2: `<strong>${t("onboardingTour.step_7.emphasized2")}</strong>`,
      }),
      attachTo: {
        element: "#step-7",
        on: "left",
      },
      classes: "ml-[-25px]",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_7.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_7.buttonRight"),
          action: tour.next,
        },
      ],
    });
    tour.addStep({
      title: t("onboardingTour.step_8.title"),
      text: t("onboardingTour.step_8.text", {
        emphasized: `<strong>${t("onboardingTour.step_8.emphasized")}</strong>`,
      }),
      attachTo: {
        element: "#step-8",
        on: "bottom",
      },
      classes: "mt-6",
      cancel: tour.cancel,
      buttons: [
        {
          text: t("onboardingTour.step_8.buttonLeft"),
          action: tour.back,
        },
        {
          text: t("onboardingTour.step_8.buttonRight"),
          action: tour.complete,
        },
      ],
    });
  };

  const localTourCompleted = useLocalStorage("lexmea-tour-completed", false, {
    mergeDefaults: true,
    initOnMounted: true,
  });

  const pageContext = usePageContext();
  const isMounted = useMounted();
  const isLargeScreen = useIsLargeScreen();
  const currentPath = toRef(pageContext, "urlPathname");

  const canShowTour = computed(
    () => isLargeScreen.value && currentPath.value.startsWith("/gesetz")
  );

  const startTour = () => tour.start();
  const finishTour = () => (localTourCompleted.value = true);

  const setupOnboardingTour = () => {
    addSteps();
    ["complete", "cancel"].forEach((event) => tour.on(event, finishTour));

    if (!localTourCompleted.value) {
      startTour();
    }
  };

  const { cookieModalDisabled } = useCookieModal();

  watchEffect(() => {
    if (!isMounted.value || !cookieModalDisabled.value) {
      return;
    }
    if (!canShowTour.value) {
      return tour.cancel();
    }
    return setupOnboardingTour();
  });

  return { canShowTour, startTour };
}
