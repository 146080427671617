export const authentication = {
  login: "Login",
  logout: "Logout",
  welcome: "Welcome!",
  not_logged_in_personalize:
    "You are currently not logged in. To save your personalizations permanently, please log in now:",
  limit_reached_editing:
    "You have reached the limit of test functions without an account. To use the editing functions without restrictions, please log in now:",
  limit_reached:
    "You have reached the limit of test functions without an account.",
  username: "Username",
  email: "Email Address",
  loginButton: "Login",
  toRegister: "To @:authentication.registration.word",
  toLogin: "To @:authentication.login",
  logged_in_successfully: "Logged in successfully.",
  logged_out_successfully: "Logged out successfully.",
  register: "Register",
  completeRegistration: "Complete Registration",
  register_free_now: "Register now for free",
  registration: {
    word: "Registration",
    register_free: "Register for free",
    confirmEmail:
      "We have sent you an email with a verification code to {email}. Please also check your spam folder.",
    codeIsValidFor: "The code is valid for one hour.",
    resendCode: "Resend Code",
    chooseUsername: "Choose your username",
    continue: "Continue",
    back: "Back",
    almostDone: "Almost done!",
    complete: "Complete",
    registerFreeNow: "Register {freeNow} now",
    freeNow: "for free",
    additionalForm: {
      description: "Help us better tailor LexMea to your needs.",
      occupation: "Occupation",
      occupationItems: {
        noSelection: "- No selection -",
      },
      city: "City",
      university: "University",
      university_optional: "University (optional)",
      studies: "Studies",
      job: "Job",
    },
  },
  verification: {
    verifying: "Your email address is being verified...",
    resend: "Resend verification email?",
    success:
      "Your email address has been successfully verified. You are logged in now and will be automatically redirected to the bookshelf.",
  },
  input: {
    forgotPassword: "Forgot password?",
    rememberMe: "Remember me",
    emailorUsername: "Email address or username",
    password: "Password",
    passwordRepeat: "Repeat password",
    passwordRegex:
      "Use 9 or more characters with a mix of letters, numbers, and symbols.",
    acceptTerms: "I agree to the {terms}.",
    terms: "terms of use",
    contactMe:
      "I want to be informed about news via email (can be revoked at any time, e.g., via link at the end of each email).",
  },
  privacy: {
    registerTitle: "Extra strong data protection",
    loginDescription:
      "We apply the highest data protection standards and process your data on European servers. Therefore, a login with Google or Facebook is unfortunately not possible. Learn {more} more about it.",
    more: "here",
    singularPoints: [
      "We never share your data with advertisers.",
      "We do not use Google Analytics, Facebook Pixel.",
      "We host on our European servers.",
    ],
    discoverMore: "Learn more in our {privacyNotes}.",
    privacyNotes: "privacy notes",
  },
  errors: {
    usernameRequired: "Username is required",
    passwordRequired: "Password is required",
    invalidCredentials: "Invalid login credentials",
    termsNeedToBeAccepted: "The terms of use must be accepted.",
    password_has_to_be_filled: "Password has to be filled",
    password_needs_letters: "Password needs letters",
    password_confirm_has_to_be_filled: "Password confirmation has to be filled",
    terms_and_conditions_has_to_be_accepted:
      "Terms and conditions have to be accepted",
    email_has_to_be_filled: "Email address has to be filled",
    email_invalid: "Email address is invalid",
    verification: "An error occured trying to verify your email address.",
  },
};
