<template>
  <button
    :class="'mr-2 flex items-center gap-1 ' + size"
    @click="$i18n.locale = $i18n.locale === 'de' ? 'en' : 'de'"
  >
    <i-material-symbols-light-language v-if="showIcon" class="size-5" />
    <span :class="{ 'font-bold': $i18n.locale === 'de' }">DE</span>
    <span
      :class="[
        'block w-px bg-current',
        { 'h-3': size === 'sm' },
        { 'h-4': size === 'md' },
      ]"
    ></span>
    <span :class="{ 'font-bold': $i18n.locale === 'en' }">EN</span>
  </button>
</template>

<script setup lang="ts">
const props = defineProps<{ size?: string; showIcon?: boolean }>();

const { size = "md", showIcon = false } = props;
</script>
