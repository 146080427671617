export const settings = {
  base_form: {
    title: "Title",
    form: "Form",
  },
  languages: {
    german: "German",
    english: "English",
  },
  toast: {
    link_successfully_sent: "Link successfully sent!",
    maximum_file_size_profile_image:
      "The maximum file size for profile pictures is 4 MB and the image files are limited to PNG, JPG or GIF.",
    error_uploading_profile_image: "Error uploading profile picture.",
    error_deleting_profile_image: "Error deleting profile picture.",
    error_deleting_account: "Error deleting account.",
  },
  hello: "Hello",
  manage_lexmea_account: "Manage LexMea Account",
  edit_account: "Manage Account",
  edit_profile: "Edit Profile",
  edit_password: "Change Password",
  data_privacy_cockpit: "Data Privacy Cockpit",
  save_changes: "Save Changes",
  change: "Change",
  abort: "Cancel",
  continue: "Continue",

  email: {
    change_email: "Change Email Address",
    current_email: "Your current email address is:",
    current_email_alt: "You are currently using the email address:",
    new_email: "Which email address would you like to use in the future?",
    labels: {
      new_email: "New Email Address",
      confirm_email: "Confirm New Email Address",
    },
  },
  username: {
    change_username: "Change Username",
    current_username: "You are currently using the username:",
    new_username: "Which username would you like to use in the future?",
    labels: {
      new_username: "New Username",
    },
  },
  profile: {
    title: "Profile",
    how_can_we_help:
      "How can LexMea help you? We do not pass on this information to third parties!",
  },
  profileImage: {
    change_profile_image_in_settings:
      "Change your profile picture / username in the profile settings.",
    delete_profileImage: "Do you want to remove your profile picture?",
    delete_profile_image_button: "Remove profile picture",
    delete: "Remove",
    tooltip_add_profile_image: "Add / change profile picture",
    edit_your_image: "Edit your image",
  },
  account: {
    delete_account: "Delete Account",
    click_here_to_delete_account:
      "Click here to permanently delete your account with all data.",
    explainer_message:
      "If you delete your account, we will irretrievably delete all personalizations you have made to your online law book, such as your markings, references, @.lower:documents, etc. We cannot restore this information afterwards.",
    confirm_password: "Enter your password to confirm:",
    last_warning:
      "You are about to permanently delete your account. If you really want to delete it, click on 'Delete Account'. Once you have submitted a request to delete your account, we will send you an email confirming the deletion process. With this, you can reactivate your account within 30 days and cancel the deletion process. After 30 days, the deletion process begins and you will no longer be able to retrieve your added content or information.",
    last_warning_2:
      "Only to the extent necessary to preserve evidence regarding the provision of any paid services of our offer, we store the metadata (but no content) for the duration of the statutory limitation periods.",
    deleted: "Your account has been deleted.",
    email_changed: "Your email address has been changed.",
  },
  privacy: {
    email_notifications: "Email Notifications",
    security_relevant_mails: "Security Relevant Emails",
    security_relevant_mails_info:
      "We only send you security relevant emails when necessary to ensure the integrity of your account or our site.",
    newsletter: "Newsletter",
    newsletter_info:
      "Spam emails are annoying. Therefore, we only send you emails with truly relevant updates and at most twice a month – for example, when introducing entirely new features on LexMea, special events in your region, or related to your legal field.",
    never: "Never",
    subscribe_newsletter: "Subscribe to Newsletter",
    relevant_news: "Relevant News",
    data_privacy_rights: "Data Privacy Rights",
    data_privacy_rights_info:
      "Data privacy is our highest priority. Therefore, we exceed the protection level required by the European General Data Protection Regulation (GDPR) in many areas. You can learn more about this in our {data_privacy_rights_link}.",
    data_privacy_rights_link: "Privacy Policy",
    data_privacy_text: {
      intro:
        "According to the General Data Protection Regulation (GDPR), every person has the right to:",
      consent_revocation: "Revoke Consent",
      access_personal_data: "Access Personal Data",
      rectification: "Rectification",
      erasure: "Erasure",
      restriction_of_processing: "Restriction of Processing",
      data_portability: "Data Portability",
      objection: "Objection",
      complaint_to_supervisory_authority:
        "Complaint to a Supervisory Authority",
      contact_us_with_your_rights:
        "To exercise any of these rights, simply send us an informal {message} and we will personally take care of your request.",
      message: "message",
      last_paragraph_1:
        "Every affected person has the right to lodge a complaint with a supervisory authority, without prejudice to any other administrative or judicial remedy.",
      last_paragraph_2:
        "The supervisory authority responsible for LexMea is the Berlin Commissioner for Data Protection and Freedom of Information.",
    },
  },
  password: {
    forgot_password: "Forgot Password?",
    forgot_password_info_text:
      "Forgot your password? Click on 'Reset Password' and we will send you a link to reset your password to your stored email address.",
    reset_password: "Reset Password",
    change_password: "Change Password",
    redirect: "You will be redirected to the bookshelf in 5 seconds.",
    send_link: "Send Link",
    regex:
      "Use at least 9 characters, including at least one number, one letter and one special character.",
    confirmation:
      "We will send you an email to reset your password to: {email} You have 60 minutes to click on the link contained therein to set a new password. Also check your spam folder.",
    labels: {
      old_password: "Old Password",
      new_password: "New Password",
      new_password_repeat: "Repeat New Password",
      password: "Password",
    },
  },
};
